import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setAircraftInfo: ['aircraftId', 'baseSpotId', 'cityId', 'cityName'],
  resetSegments: [],
});

const INITIAL_STATE = {
  aircraftModelId: null,
  baseSpot: null,
  cityId: null,
  cityName: null,
};

const setAircraftInfo = (state = INITIAL_STATE, action) => ({
  ...state,
  aircraftModelId: action.aircraftId,
  baseSpot: action.baseSpotId,
  cityId: action.cityId,
  cityName: action.cityName,
});

const resetSegments = (state = INITIAL_STATE, action) => ({
  ...state,
  aircraftModelId: null,
  baseSpot: null,
  cityId: null,
  cityName: null,
});

const HANDLERS = {
  [Types.SET_AIRCRAFT_INFO]: setAircraftInfo,
  [Types.RESET_SEGMENTS]: resetSegments,
};

export default createReducer(INITIAL_STATE, HANDLERS);
