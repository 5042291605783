import {
  apiHost,
  loginRoute,
  getUser as getUserUrl,
  signupRoute,
  forgotPasswordRoute,
  facebookLoginRoute,
  putUserRoute,
  resetPasswordRoute,
  resetPasswordOperatorRoute,
  siteDomain,
  backofficeDomain,
  saltJwt,
  addContactPhoneRoute,
  sendVerificationCodeRoute,
  validateVerificationCodeRoute,
} from '@utils/constants';
import { IP_STACK_API } from '@gatsby-local-plugin/gatsby-local-env-variables';
import axios from 'axios';
import { navigate } from 'gatsby';
import md5 from 'md5';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import useRequest from '@utils/useRequest';
import { gtmDispatcher } from '@utils/gtm.helper';

import { setCookie, removeCookie } from '@utils/cookies.helper';
import { analyticsGTAG } from '@utils/ga.helper';
import { translate as t } from '@utils/translate';
import getCountryIso3 from '@utils/getCountryIso-2-to-3';

import { decodeObjectToken } from '@utils/token';
import store from '@store/../state/store';

import CryptoJS from 'crypto-js';

// Envs
import { WEBAPP_V3_LOCALSTORAGE } from '@gatsby-local-plugin/gatsby-local-env-variables';

export const isBrowser = () => typeof window !== 'undefined';

export const getUserFromNewWebapp = () => {
  if (!isBrowser()) return null;

  const encryptedData = JSON.parse(window.localStorage.getItem('user-storage'));

  if (!encryptedData) return {};

  const decryptedData = CryptoJS.AES.decrypt(
    encryptedData,
    WEBAPP_V3_LOCALSTORAGE
  ).toString(CryptoJS.enc.Utf8);

  const state = JSON.parse(decryptedData)?.state;

  return state?.user?.id ? state?.user : {};
};

export const getUser = () => {
  if (!isBrowser()) return {};

  const oldWebappUser = window.localStorage.getItem('flapperUser')
    ? JSON.parse(window.localStorage.getItem('flapperUser')).profile
    : {};

  const newWebappUser = getUserFromNewWebapp();

  if (newWebappUser?.id) return newWebappUser?.profile;
  else if (oldWebappUser?.id) return oldWebappUser;

  return {};
};

export const getAllDataUser = () =>
  isBrowser() && window.localStorage.getItem('flapperUser')
    ? JSON.parse(window.localStorage.getItem('flapperUser'))
    : {};

export const setUserStorage = user => {
  const flapperUser = JSON.stringify(user);
  const flapperSessionKey = user?.session?.publicKey;
  if (isBrowser() && window.document) {
    window.dispatchEvent(
      new CustomEvent('onSetUser', {
        detail: {
          user: flapperUser,
        },
      })
    );
    window.localStorage.setItem('flapperUser', flapperUser);

    if (flapperSessionKey) setCookie('flapperUser', flapperSessionKey, 30);
  }
};

export const getUserStorage = () =>
  isBrowser() && window.localStorage.getItem('flapperUser')
    ? JSON.parse(window.localStorage.getItem('flapperUser'))
    : {};

export const getSession = () =>
  isBrowser() && window.localStorage.getItem('flapperUser')
    ? JSON.parse(window.localStorage.getItem('flapperUser')).session
    : {};

export const getHomeSpots = () =>
  isBrowser() && window.localStorage.getItem('flapperUser')
    ? JSON.parse(window.localStorage.getItem('flapperUser')).homeSpots
    : {};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user?.id;
};

/* Login */
export const handleLogin = async (
  setLoading,
  closeModal,
  setErrorMsg,
  { email, password },
  alreadyEncrypted,
  headers = {}
) => {
  setLoading && setLoading(true);
  setErrorMsg && setErrorMsg('');

  let data;
  const md5Password = alreadyEncrypted ? password : md5(password);
  const msgBody = { user: { emailAddress: email, password: md5Password } };
  const url = apiHost + loginRoute;

  try {
    const customHeaders = {
      'X-Forwarded-For': null,
      ...headers,
    };

    const {
      data: { ip },
    } = await axios.get(
      `https://api.ipstack.com/check?access_key=${IP_STACK_API}&fields=ip`
    );

    customHeaders['X-Forwarded-For'] = ip + ',';

    const { data } = await useRequest({
      url: url,
      method: 'post',
      useSession: false,
      customHeaders,
      data: JSON.stringify(msgBody),
    });

    if (data.result.status === 0) {
      analyticsGTAG('click', {
        event_category: 'User',
        event_action: 'Login',
        event_label: 'User login',
      });

      gtmDispatcher('user_info', {
        event_category: 'user',
        event_action: 'submit',
        event_label: 'login',
        value: data.user.emailAddress,
      });

      setUserStorage(data.user);
      if (closeModal) closeModal();
    } else if (data.result.status === 9) {
      setErrorMsg && setErrorMsg(t('incorrectEmailPassword'));
    } else if (data.result.status === 2) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg('');
      return data.errorData;
    } else setErrorMsg && setErrorMsg(t('unexpectedError'));
  } catch (e) {
    setErrorMsg && setErrorMsg(t('unexpectedError'));
  }

  setLoading && setLoading(false);

  return data;
};

/* SignUp */
export const handleSignup = async (
  setLoading,
  closeModal,
  setErrorMsg,
  { fullName, email, password, country, inviteCode },
  headers = {}
) => {
  const md5Password = md5(password);

  setLoading(true);
  setErrorMsg('');

  const msgBody = {
    user: {
      inviteCode,
      emailAddress: email,
      password: md5Password,
      profile: {
        emailAddress: email,
        fullName: fullName,
        countryOfBirth: {
          iso2Code: country,
          iso3Code: getCountryIso3(country),
        },
      },
    },
  };
  const url = apiHost + signupRoute;

  const customHeaders = {
    'X-Forwarded-For': null,
    ...headers,
  };

  const {
    data: { ip },
  } = await axios.get(
    `https://api.ipstack.com/check?access_key=${IP_STACK_API}&fields=ip`
  );

  customHeaders['X-Forwarded-For'] = ip + ',';

  const { data } = await useRequest({
    url: url,
    method: 'post',
    useSession: false,
    data: JSON.stringify(msgBody),
    customHeaders,
  });

  if (data.result.status === 0) {
    if (typeof closeModal === 'function') closeModal();

    analyticsGTAG('click', {
      event_category: 'User',
      event_action: 'Signup',
      event_label: 'User signup',
    });

    gtmDispatcher('user_info', {
      event_category: 'user',
      event_action: 'signup',
      event_label: 'User creates an account',
    });
  } else if (data.result.status === 6) {
    setErrorMsg(t('userAlreadyRegistered'));
  } else {
    setErrorMsg(t('unexpectedError'));
  }

  setLoading(false);
};

/* Forgot Password */
export const handleForgotPassword = async (
  setLoading,
  setErrorMsg,
  goToSuccess,
  email
) => {
  setLoading(true);
  setErrorMsg('');

  const msgBody = { emailAddress: email };
  const url = apiHost + forgotPasswordRoute;

  try {
    const { data } = await useRequest({
      url: url,
      method: 'post',
      useSession: false,
      data: JSON.stringify(msgBody),
    });

    if (data.result.status === 0) {
      goToSuccess();
    } else if (data.result.status === 15) setErrorMsg('Usuário não encontrado');
    else
      setErrorMsg(
        'Ops, ocorreu um erro inesperado! Tente novamente em alguns instantes.'
      );
  } catch (e) {
    setErrorMsg(
      'Ops, ocorreu um erro inesperado! Tente novamente em alguns instantes.'
    );
  }

  setLoading(false);
};

/* Updates user phone to send verification code */
export const handleUpdateUserPhone = async (
  setLoading,
  setErrorMsg,
  onSuccess,
  contactPhone,
  userCode
) => {
  setLoading(true);
  setErrorMsg('');

  const msgBody = { contactPhone, userCode };
  const url = apiHost + addContactPhoneRoute;

  try {
    const { data } = await useRequest({
      url: url,
      method: 'patch',
      useSession: false,
      data: JSON.stringify(msgBody),
    });

    if (data.result.status === 0) {
      onSuccess(contactPhone, userCode);
    } else if (data.result.status === 121) setErrorMsg(t('phoneAlreadyExists'));
    else {
      setErrorMsg(data?.msg);
    }
  } catch (e) {
    setErrorMsg(
      'Ops, ocorreu um erro inesperado! Tente novamente em alguns instantes.'
    );
  }

  setLoading(false);
};

/* Resend verification code to user's phone */
export const handleSendVerificationCode = async (
  setLoading,
  setErrorMsg,
  userCode,
  channel
) => {
  setLoading(true);
  setErrorMsg('');

  const payload = { userCode, verificationChannel: channel };
  const url = apiHost + sendVerificationCodeRoute;
  let serviceSid = null;

  try {
    const { data } = await useRequest({
      url: url,
      method: 'post',
      useSession: false,
      data: payload,
    });

    if (data.result.status === 0) {
      serviceSid = data?.serviceSid;
    } else setErrorMsg(data?.msg);
  } catch (e) {
    setErrorMsg(
      'Ops, ocorreu um erro inesperado! Tente novamente em alguns instantes.'
    );
  }

  setLoading(false);

  return serviceSid;
};

/* Send verifcation code to validate user account */
export const handleValidateVerificationCode = async (
  setLoading,
  setErrorMsg,
  goToSuccess,
  code,
  userCode,
  serviceSid,
  verificationChannel
) => {
  setLoading(true);
  setErrorMsg('');

  const payload = { code, userCode, serviceSid, verificationChannel };
  const url = apiHost + validateVerificationCodeRoute;

  try {
    const { data } = await useRequest({
      url: url,
      method: 'post',
      useSession: false,
      data: payload,
    });

    if (data.result.status === 0) goToSuccess();
    else if (data.result.status === 5) setErrorMsg(t('invalidCode'));
  } catch (e) {
    setErrorMsg(
      'Ops, ocorreu um erro inesperado! Tente novamente em alguns instantes.'
    );
  }

  setLoading(false);
};

export const logout = () => {
  if (isBrowser()) {
    window.localStorage.removeItem('flapperUser');
    removeCookie('x-public', '/');
    // implementation for webapp 3.0v
    window.localStorage.removeItem('user-storage');
    removeCookie('flapperUser');
    navigate('/');
  }
};

export const logoutWithRedirect = redirectTo => {
  if (isBrowser()) {
    window.localStorage.removeItem('flapperUser');
    removeCookie('flapperUser');
    window.location.href = redirectTo;
  }
};

export const handleFacebookLogin = async (accessToken, redirectTo) => {
  const msgBody = {
    user: { facebook: { authResponse: { accessToken } } },
  };
  const url = apiHost + facebookLoginRoute;
  const customHeaders = {
    'X-Forwarded-For': null,
  };

  const {
    data: { ip },
  } = await axios.get(
    `https://api.ipstack.com/check?access_key=${IP_STACK_API}&fields=ip`
  );

  customHeaders['X-Forwarded-For'] = ip + ',';

  try {
    const { data } = await useRequest({
      url: url,
      method: 'post',
      useSession: false,
      customHeaders,
      data: JSON.stringify(msgBody),
    });

    if (data.result.status === 0) {
      analyticsGTAG('click', {
        event_category: 'User',
        event_action: 'LoginFacebook',
        event_label: 'User login facebook',
      });

      gtmDispatcher('user_info', {
        event_category: 'user',
        event_action: 'submit',
        event_label: 'login with facebook',
        value: data.user.emailAddress,
      });

      setUserStorage(data.user);

      window.localStorage.setItem('anonymous-quotation', false);

      if (!redirectTo) {
        location.reload();
      } else {
        window.location.href = redirectTo;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const handleUpdateUser = async (setLoading, setErrorMsg, user) => {
  setLoading(true);
  setErrorMsg('');

  const msgBody = { profile: user };
  const url = apiHost + putUserRoute;
  const session = getSession();

  try {
    const { data } = await useRequest({
      url: url,
      method: 'put',
      useSession: true,
      customHeaders: { x_hash: hmacSHA256(msgBody, session.privateKey) },
      data: JSON.stringify(msgBody),
    });

    if (data.result.status === 0) {
      const userStorage = getUserStorage();
      setUserStorage({ ...userStorage, profile: data.profile });
      location.reload();
    } else setErrorMsg(t('unexpectedError'));
  } catch (e) {
    setErrorMsg(t('unexpectedError'));
  } finally {
    setLoading(false);
  }
};

/* Login */
export const handleResetPassword = async (
  setLoading,
  setErrorMsg,
  setSuccess,
  { password, hash }
) => {
  setLoading(true);
  setErrorMsg('');
  setSuccess('');
  let url = '';
  let msgBody = '';
  let { type, token, email } = decodeObjectToken(hash, saltJwt);
  let redirectTo = '';
  const TEXTS = {
    INVALID_TOKEN: t('invalidToken'),
    UNEXPECTED_ERROR: t('unexpectedError'),
    SUCCESS_MSG: t('resetPasswordSuccess'),
  };

  if (type === 'operator') {
    url = apiHost + resetPasswordOperatorRoute;
    redirectTo = backofficeDomain;
    msgBody = {
      operator: { password: password, emailAddress: email },
      token: token,
    };
  } else {
    msgBody = { user: { password: password }, token: token };
    redirectTo = siteDomain;
    url = apiHost + resetPasswordRoute;
  }

  try {
    useRequest({
      url: url,
      method: 'put',
      useSession: false,
      data: JSON.stringify(msgBody),
    })
      .then(({ data }) => {
        if (data.result.status === 0) {
          setSuccess(redirectTo);
        } else if (data.result.status === 12) {
          setErrorMsg(TEXTS.INVALID_TOKEN);
        } else {
          setErrorMsg(TEXTS.UNEXPECTED_ERROR);
        }
      })
      .catch(err => {
        console.error(err);
        setErrorMsg(TEXTS.UNEXPECTED_ERROR);
      });
  } catch (err) {
    console.error(err);
    setErrorMsg(TEXTS.UNEXPECTED_ERROR);
  }

  setLoading(false);
};

export const handleUpdatePosition = async position => {
  const allUserData = getAllDataUser();
  const { latitude, longitude } = position.coords;

  const user = {
    id: allUserData?.id,
    personId: allUserData?.personId,
    currentLocalization: allUserData?.currentLocalization,
    profile: { id: allUserData?.profile?.id },
  };

  user.currentLocalization = {
    ...user.currentLocalization,
    latitude,
    longitude,
  };

  const url = apiHost + putUserRoute;
  const session = getSession();

  if (!session?.privateKey) return;

  const { data } = await useRequest({
    url: url,
    method: 'put',
    useSession: true,
    customHeaders: { x_hash: hmacSHA256(user, session.privateKey) },
    data: JSON.stringify(user),
  });

  const updatedUser = {
    ...allUserData,
    profile: data?.profile || allUserData.profile,
  };

  if (data.result.status === 0) {
    setUserStorage(updatedUser);
  }
};

export const getUserFromDb = async ({ publicKey }) => {
  try {
    const state = store.getState();
    const lang = state.general.language;
    const url = apiHost + getUserUrl;

    const response = await fetch(url, {
      headers: {
        x_public: publicKey,
        'Accept-Language': lang,
        client: 'Web App;4.1.0',
      },
    });

    const data = await response.json();

    return data;
  } catch (error) {
    return error;
  }
};
