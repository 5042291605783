import { pt, enUS, es, it } from 'date-fns/locale';
import { langDict } from '@utils/constants';
import { getCurrentLang } from '@utils/language';
import TRANSLATIONS from '@gatsby-local-plugin/flapper-gatsby-translations/data/translations.json';
import TRANSLATIONS_FALLBACK from '@gatsby-local-plugin/flapper-gatsby-translations/data/translations-fallback.json';

const dictionaryFallback = {
  ...TRANSLATIONS_FALLBACK,
};

const dictionary = {
  ...TRANSLATIONS,
};

export const translate = (text, lang) => {
  const language = lang || getCurrentLang();
  return translate_to(text, language);
};

export const translate_to = (text, lang) => {
  if (!text) return text;
  const lowerText = text.toLowerCase();
  const lowerLang = lang.toLowerCase().replace('_', '-');
  let localizedText =
    dictionary[lowerText] || dictionary[text] || dictionaryFallback[text];
  return localizedText && localizedText[lowerLang]
    ? localizedText[lowerLang]
    : text;
};

export const useCurrentLang = () => {
  const lang = getCurrentLang();
  return lang;
};

export const getLangParam = currentLang => {
  const urlParams = new URLSearchParams(window.location.search);
  let lang = urlParams.get('lang');

  if (!lang) lang = getLangByOrigin(currentLang);

  return lang;
};

const getLangByOrigin = currentLang => {
  let lang = currentLang;

  if (typeof window !== 'undefined') {
    Object.keys(langDict).forEach(key => {
      const origin = window.location.origin;
      const href = `${window.location.href}/`;

      if (href.includes(`${origin}/${key}/`)) {
        lang = langDict[key]['variant'];
        return false;
      }
    });
  } else {
    console.warn(
      'getLangByOrigin is being called outside SSR security context. Use it inside an useEffect wrapper.'
    );
  }

  return lang;
};

export const intlLangToSymbol = (currentLang, matchDefault = true) => {
  let langSymbol = '';
  if (currentLang !== 'pt-BR' && currentLang !== 'pt_BR') {
    Object.keys(langDict).forEach(key => {
      if (langDict[key][matchDefault ? 'default' : 'variant'] === currentLang) {
        langSymbol = key;
      }
    });
  }

  return langSymbol;
};

export const hasLangSymbolInPath = lang => {
  let hasSymbol = '';
  if (typeof window !== 'undefined') {
    if (typeof window !== 'undefined') {
      Object.keys(langDict).forEach(key => {
        if (!lang || langDict[key]['default'] === lang) {
          const origin = window.location.origin;
          const href = window.location.href;

          if (href.includes(`${origin}/${key}/`)) {
            hasSymbol = key;
            return false;
          }
        }
      });
    } else {
      console.warn(
        'getLangByOrigin is being called outside SSR security context. Use it inside an useEffect wrapper.'
      );
    }
  }

  return hasSymbol;
};

export const useCurrentLocale = () => {
  const lang = useCurrentLang();

  switch (lang) {
    case 'en_US':
      return enUS;
    case 'es_ES':
      return es;
    // case 'it_IT':
    //   return it;
    default:
      return pt;
  }
};

export const parseLangType = language => language.split('_').join('-');
