import { Types } from './actions';

const INITIAL_STATE = {
  showWpp: true,
};

const LayoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CHANGE_WPP_VISIBILITY: {
      return { ...state, showWpp: action.value };
    }
    default: {
      return state;
    }
  }
};

export default LayoutReducer;
