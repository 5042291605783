import { createActions } from 'reduxsauce';

// Create actions and types to be passed to the dispatcher
const actions = createActions(
  {
    addError: ['error', 'dispatcher'],
    removeError: ['dispatcher'],
    reset: null,
  },
  { prefix: 'errors/' }
);

export const Creators = actions.Creators;
export const Types = actions.Types;

export default {
  Creators,
  Types,
};
