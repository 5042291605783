import { Types } from './actions';

const INITIAL_STATE = {
  searchTextValue: '',
  selectedSearchTerm: null,

  fetchingAircraft: false,

  aircraftsResult: null,
  aircraftsResultPagination: {
    totalItems: 0,
    page: 1,
    totalLoadedItems: 0,
    totalPages: 0,
    limit: 0,
  },

  suggestions: null,
  defaultSuggestions: [],

  searchTermEndPoint: null,
  aircraftGetEndpoint: null,
  aircraftModelId: null,
};

const FleetSearchReducer = (state = INITIAL_STATE, action) => {
  let newState = null;
  switch (action.type) {
    case Types.SET_SEARCH_TEXT_VALUE:
      newState = {
        ...state,
        searchTextValue: action.value || '',
      };
      // if (newState.searchTerm == "") {
      //   newState.selectedSearchTerm = null;
      // }
      return newState;

    case Types.SET_SELECTED_SEARCH_TERM:
      newState = {
        ...state,
        selectedSearchTerm: action.selectedSearchTerm,
      };

      if (newState.selectedSearchTerm == null) {
        newState.fetchingAircraft = false;
        newState.results = null;
      }

      return newState;

    case Types.SET_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions || state.suggestions,
        defaultSuggestions:
          action.defaultSuggestions || state.defaultSuggestions,
      };

    /** Aircraft */
    case Types.SET_AIRCRAFT_GET_ENDPOINT:
      return {
        ...state,
        aircraftGetEndpoint: action.aircraftGetEndpoint,
      };

    case Types.SET_SEARCH_RESULT_AIRCRAFTS:
      return {
        ...state,
        aircraftsResult: action.aircrafts,
      };

    case Types.SET_SEARCH_RESULT_PAGINATION:
      return {
        ...state,
        aircraftsResultPagination: action.pagination,
      };

    case Types.SET_SEARCH_TERM_ENDPOINT:
      return {
        ...state,
        searchTermEndPoint: action.searchTermEndPoint,
      };

    case Types.START_FETCHING_AIRCRAFT:
      return {
        ...state,
        fetchingAircraft: true,
      };

    case Types.END_FETCHING_AIRCRAFT:
      return {
        ...state,
        fetchingAircraft: false,
      };

    case Types.SET_AIRCRAFT_MODEL_ID:
      return {
        ...state,
        aircraftModelId: action.id,
      };

    case Types.RESET:
      return { ...initialState };

    default:
      return state;
  }
};

export default FleetSearchReducer;
