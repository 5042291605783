import axios from 'axios';
import useAuth from './useAuth';
import store from '@store/../state/store';
import { setRequests } from '@store/reducers/general/operations';

import { translate as t } from './translate';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    let errorMsg = err.response
      ? err.response.data.errors?.length
        ? err.response.data.errors[0]?.text
        : err.response.data.result.message
      : null;

    if (err?.response?.data?.result?.message) {
      errorMsg = err.response.data.result.message;
    }

    try {
      const errorResponse = {
        data: {
          result: { status: err.response.data.result.status, message: 'FAIL' },
          msg: errorMsg || t('somethingWrongMsg'),
          status: err.response.status,
          statusText: err.response.statusText,
          errors: err.response.data.errors || [],
          errorData: err.response.data.user,
        },
      };
      return errorResponse;
    } catch (error) {
      return {
        data: {
          msg: errorMsg || t('somethingWrongMsg'),
          result: {
            status: 1,
          },
        },
        error,
        status: 400,
      };
    }
  }
);

/**
 * Builds header object to be used in axios instance
 *
 * @param {boolean} useSession Flag to use session key on requests
 * @param {string} client Client's header
 * @param {string} sessionToken Force to use this token instead of auth's
 */
const buildHeaders = (useSession, client = 'Web App;4.1.0', sessionToken) => {
  const { session } = useAuth();
  const state = store.getState();
  const lang = state.general.language;

  const headersObject = {
    Client: client,
    'Accept-Language': lang,
  };

  if (useSession && session?.publicKey) {
    headersObject['x_public'] = session.publicKey;
  } else if (useSession && sessionToken) {
    headersObject['x_public'] = sessionToken;
  }

  return {
    ...headersObject,
  };
};

/**
 * Request method using Axios
 *
 * @param {object} params The configuration object params
 */
const useRequest = ({
  url,
  method = 'get',
  mockup = null,
  customHeaders = null,
  useSession = true,
  sessionToken = '',
  useCurrency = true,
  context = '',
  client = 'Web App;4.1.0',
  ...options
}) => {
  if (mockup) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ data: mockup });
      }, 1000);
    });
  } else {
    const source = axios.CancelToken.source();
    let cancelToken = source.token;

    const requestObj = {
      url,
      method,
      headers: {
        ...buildHeaders(useSession, client, sessionToken),
        ...customHeaders,
      },
      cancelToken,
      ...options,
    };
    const { requests } = store.getState().general;

    if (context) {
      if (requests[context]) requests[context].cancel({ status: 'canceled' });
      store.dispatch(setRequests(source, context));
    }

    return axiosInstance.request(requestObj);
  }
};

export default useRequest;
