import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import fleet from './fleet';
import searchSegments from './search-segments';
import cart from './cart';
import address from './address';
import proposals from './proposals';
import quotations from './quotations';
import errors from './errors';
import general from './general';
import filters from './filters';
import layout from './layout';

const rootReducer = combineReducers({
  fleet,
  searchSegments,
  cart,
  address,
  proposals,
  quotations,
  errors,
  general,
  filters,
  layout,
});

const persistConfig = {
  key: 'search',
  storage,
  whitelist: ['searchSegments', 'quotations'],
};

export default persistReducer(persistConfig, rootReducer);
