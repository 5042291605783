import { Types } from './actions';

const INITIAL_STATE = {};

const ErrorsReducer = (state = INITIAL_STATE, action) => {
  let newState = null;

  switch (action.type) {
    case Types.ADD_ERROR:
      newState = {
        ...state,
        [action.dispatcher]: action.error,
      };
      return newState;

    case Types.REMOVE_ERROR:
      newState = {
        ...state,
        [action.dispatcher]: null,
      };
      return newState;

    default:
      return state;
  }
};

export default ErrorsReducer;
