import { Types } from './actions';

const INITIAL_STATE = {
  segments: {},
};

const QuotationsReducer = (state = INITIAL_STATE, action) => {
  let newState = null;

  switch (action.type) {
    case Types.SET_QUOTATIONS_DATA:
      newState = {
        ...state,
        segments: action.payload || {},
      };
      return newState;

    default:
      return state;
  }
};

export default QuotationsReducer;
