import store from '@store/../state/store';

const getLangFromLocalStorage = () => {
  const lang = localStorage.getItem('language');
  return !lang || lang === 'undefined' ? 'pt_BR' : lang;
};

const getLangParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let language = urlParams.get('lang');

  return language;
};

const getCurrentLang = () => {
  const state = store.getState();
  const language =
    !state.general.language || state.general.language === 'undefined'
      ? 'pt_BR'
      : state.general.language;

  return language;
};

const getLangByCode = code => {
  const langs = {
    en: 'en_US',
    pt: 'pt_BR',
    es: 'es_ES',
    // it: 'it_IT',
  };

  return langs[code] || 'pt_BR';
};

export { getLangFromLocalStorage, getLangParam, getCurrentLang, getLangByCode };
