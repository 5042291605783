import { createActions } from 'reduxsauce';

// Create actions and types to be passed to the dispatcher
const actions = createActions(
  {
    initFilters: ['payload'],
    updateFilters: ['payload'],
    updateGroupBy: ['name'],
    updateCurrentTab: ['tab'],
    setInitialAutonomy: ['payload'],
    reset: null,
  },
  { prefix: 'filters/' }
);

export const Creators = actions.Creators;
export const Types = actions.Types;

export default {
  Creators,
  Types,
};
