export const analyticsGA = (type = 'send', params = {}) => {
  if (typeof ga === 'function') {
    ga(type, { ...params });
  }
};

export const analyticsGTAG = (eventName, params = {}) => {
  if (typeof gtag === 'function') {
    gtag('event', eventName, { ...params });
  }
};
