import { createActions } from 'reduxsauce';

// Create actions and types to be passed to the dispatcher
const actions = createActions(
  {
    fetchCartData: null,
    setCartData: ['payload'],
    fetchPaymentMethods: null,
    fetchInstallments: ['cartToken', 'currency', 'cardIds'],
    fetchUserData: null,
    setUserData: ['value'],
    updateUserData: ['value'],
    setSelectedPaymentMethod: ['value'],
    setSelectedInstallments: ['value'],
    setSelectedCardCode: ['value'],
    setPaymentMethodsList: ['list'],
    fetchPassengersList: null,
    setPassengersList: ['list'],
    setSeats: ['available', 'filled', 'minimum'],
    addPassenger: ['passenger'],
    updatePassenger: ['passenger'],
    toggleSelectedPassenger: ['passengerId', 'selected'],
    setBillingAddress: ['address'],
    addCreditCard: ['creditCard'],
    setInvoicingAddress: ['address'],
    setStatus: ['status'],
    setCardBrands: ['brands'],
    setCreditCardFormData: ['formData'],
    reset: null,
  },
  { prefix: 'cart/' }
);

export const Creators = actions.Creators;
export const Types = actions.Types;

export default {
  Creators,
  Types,
};
