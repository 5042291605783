import { createActions } from 'reduxsauce';

// Create actions and types to be passed to the dispatcher
const actions = createActions(
  {
    changeWppVisibility: ['value'],
  },
  { prefix: 'layout/' }
);

export const Creators = actions.Creators;
export const Types = actions.Types;

export default {
  Creators,
  Types,
};
