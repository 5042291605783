import { Types } from './actions';

const INITIAL_STATE = {
  cartData: null,
  totalPrice: 0,
  paymentMethods: [],
  selectedPaymentMethod: null,
  selectedInstallments: null,
  selectedCardCode: null,
  userData: null,
  passengers: [],
  seats: {
    available: 1,
    filled: 0,
  },
  selectedPassengers: [],
  selectedBillingAddress: null,
  selectedInvoicingAddress: null,
  cardBrands: [],
  isFetching: null,
  creditCardFormData: {
    country: 'BR',
    dlocalInfo: {
      number: 0,
    },
  },
};

const CartReducer = (state = INITIAL_STATE, action) => {
  let newState = null;

  switch (action.type) {
    case Types.SET_CART_DATA:
      newState = {
        ...state,
        cartData: action.payload || null,
        totalPrice: action.payload ? action.payload.totalPrice : null,
      };
      return newState;

    case Types.SET_PAYMENT_METHODS_LIST:
      newState = {
        ...state,
        paymentMethods: action.list || [],
      };
      return newState;

    case Types.SET_SELECTED_PAYMENT_METHOD:
      newState = {
        ...state,
        selectedPaymentMethod: action.value || null,
      };
      return newState;

    case Types.SET_SELECTED_INSTALLMENTS:
      return {
        ...state,
        selectedInstallments: action.value,
      };
    case Types.SET_SELECTED_CARD_CODE:
      return {
        ...state,
        selectedCardCode: action.value,
      };
    case Types.SET_USER_DATA:
      return {
        ...state,
        userData: action.value,
      };
    case Types.UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.value,
      };
    case Types.UPDATE_PASSENGER:
      const newPassengers = state.passengers.map(passenger => {
        if (passenger.id === action.passenger.id) {
          return action.passenger;
        }
        return passenger;
      });

      return {
        ...state,
        passengers: newPassengers,
      };
    case Types.SET_PASSENGERS_LIST:
      newState = {
        ...state,
        passengers: [...(action.list || [])],
      };
      return newState;

    case Types.SET_SEATS:
      newState = {
        ...state,
        seats:
          {
            available: action.available,
            filled: action.filled,
            minimum: action.minimum,
          } || null,
      };
      return newState;

    case Types.ADD_PASSENGER:
      newState = {
        ...state,
        passengers: [...state.passengers, action.passenger],
      };
      return newState;

    case Types.TOGGLE_SELECTED_PASSENGER:
      const passengers = state.passengers.map((passenger, index) => {
        if (passenger.id === action.passengerId)
          passenger.selected = action?.selected;
        return passenger;
      });

      newState = {
        ...state,
        selectedPassengers: passengers.filter(passenger => passenger?.selected),
      };
      return newState;

    case Types.ADD_CREDIT_CARD:
      newState = {
        ...state,
        paymentMethods: [{ ...action.creditCard }, ...state.paymentMethods],
        isFetching: 'fetched',
      };
      return newState;

    case Types.SET_BILLING_ADDRESS:
      newState = {
        ...state,
        selectedBillingAddress: action.address,
      };
      return newState;

    case Types.SET_INVOICING_ADDRESS:
      newState = {
        ...state,
        selectedInvoicingAddress: action.address,
      };
      return newState;

    case Types.SET_STATUS:
      newState = {
        ...state,
        isFetching: action.status,
      };
      return newState;

    case Types.SET_CARD_BRANDS:
      newState = {
        ...state,
        cardBrands: action.brands,
      };
      return newState;

    case Types.SET_CREDIT_CARD_FORM_DATA:
      newState = {
        ...state,
        creditCardFormData: action.formData || INITIAL_STATE.creditCardFormData,
      };
      return newState;

    default:
      return state;
  }
};

export default CartReducer;
