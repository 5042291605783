import { createActions } from 'reduxsauce';

// Create actions and types to be passed to the dispatcher
const actions = createActions(
  {
    setLanguage: ['lang'],
    setCurrency: ['currency'],
    setRequests: ['request', 'context'],
  },
  { prefix: 'general/' }
);

export const Creators = actions.Creators;
export const Types = actions.Types;

export default {
  Creators,
  Types,
};
