const IAircraftFilterOptions = {
  groupBy: 'producers',
  currentTab: 'airplanes',
  airplanes: {
    originalSeats: {
      min: 0,
      max: 0,
    },
    originalAutonomy: {
      min: 0,
      max: 0,
    },
    autonomy: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    price: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    seats: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    groupsList: [],
    producersList: [],
    modelsList: [],
  },
  helicopters: {
    originalSeats: {
      min: 0,
      max: 0,
    },
    originalAutonomy: {
      min: 0,
      max: 0,
    },
    autonomy: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    price: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    seats: {
      min: 9999999,
      max: 0,
      value: {
        min: 9999999,
        max: 0,
      },
    },
    groupsList: [],
    producersList: [],
    modelsList: [],
  },
};

export default IAircraftFilterOptions;
