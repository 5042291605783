import { createActions } from 'reduxsauce';

const actions = createActions(
  {
    setSearchTextValue: ['value'],
    setSelectedSearchTerm: ['selectedSearchTerm'],
    setSuggestions: { defaultSuggestions: null, suggestions: null },
    setAircraftGetEndpoint: ['aircraftGetEndpoint'],
    setSearchTermEndpoint: ['searchTermEndPoint'],
    searchSuggestions: ['term'],

    fetchAircraft: null,
    startFetchingAircraft: null,
    endFetchingAircraft: null,

    setSearchResultAircrafts: ['aircrafts'],
    setSearchResultPagination: ['pagination'],
    setAircraftModelId: ['id'],
    reset: null,
  },
  { prefix: 'fleet/' }
);

export const Creators = actions.Creators;
export const Types = actions.Types;

export default {
  Creators,
  Types,
};
