import { Types } from './actions';

const INITIAL_STATE = {
  language: 'pt_BR',
  currency: {
    code: 'BRL',
    name: 'Real',
  },
  requests: {},
};

const GeneralReducer = (state = INITIAL_STATE, action) => {
  let newState = null;

  switch (action.type) {
    case Types.SET_LANGUAGE:
      newState = {
        ...state,
        language: action.lang || 'pt_BR',
      };
      return newState;

    case Types.SET_CURRENCY:
      newState = {
        ...state,
        currency: action.currency,
      };
      return newState;

    case Types.SET_REQUESTS:
      newState = {
        ...state,
        requests: {
          ...state.requests,
          [action.context]: action.request,
        },
      };
      return newState;

    default:
      return state;
  }
};

export default GeneralReducer;
