import { setCookie, removeCookie } from '@utils/cookies.helper';

/**
 * Add tracking information to the data layer
 *
 * @param {string} event Event to be triggered
 * @param {string} type Type of the event
 * @param {object} params Params to be passed with the event
 */
const gtmDispatcher = (eventName, dlVariables = {}) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      ...dlVariables,
    });
  }
};

/**
 * Allows/Disallows gtm cookie
 *
 * @param {string} allowed Used for user consentment
 */
const gtmSetCookie = allowed => {
  if (allowed) setCookie('gatsby-gdpr-google-tagmanager', true, 30);
  else removeCookie('gatsby-gdpr-google-tagmanager');
};

export { gtmDispatcher, gtmSetCookie };
