import { createActions } from 'reduxsauce';

// Create actions and types to be passed to the dispatcher
const actions = createActions(
  {
    setProposalsData: ['payload'],
    setAircraftsDetails: ['payload'],
    setSelectedProposal: ['payload'],
    reset: null,
  },
  { prefix: 'proposals/' }
);

export const Creators = actions.Creators;
export const Types = actions.Types;

export default {
  Creators,
  Types,
};
