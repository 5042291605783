import { Types } from './actions';

const INITIAL_STATE = {
  proposalData: {},
  aircraftsDetails: {},
  selectedProposal: null,
};

const ProposalReducer = (state = INITIAL_STATE, action) => {
  let newState = null;

  switch (action.type) {
    case Types.SET_PROPOSALS_DATA:
      newState = {
        ...state,
        proposalData: action.payload || {},
        aircraftsDetails: {},
      };
      return newState;

    case Types.SET_AIRCRAFTS_DETAILS:
      newState = {
        ...state,
        aircraftsDetails: {
          ...state.aircraftsDetails,
          [action.payload.id]: { ...action.payload },
        },
      };
      return newState;

    case Types.SET_SELECTED_PROPOSAL:
      newState = {
        ...state,
        selectedProposal: {
          ...action.payload,
          owner: state.proposalData.owner,
          paymentMethodsAllowed: state.proposalData.paymentMethodsAllowed,
          requestDocumentForInvoice:
            state.proposalData.requestDocumentForInvoice,
        },
      };
      return newState;

    default:
      return state;
  }
};

export default ProposalReducer;
