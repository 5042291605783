import { GROWTHBOOK_API_KEY } from '@gatsby-local-plugin/gatsby-local-env-variables';
import { GrowthBook } from '@growthbook/growthbook-react';

const growthbookInstance = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTHBOOK_API_KEY,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

export default growthbookInstance;
