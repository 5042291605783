import React from 'react';
import { Helmet } from 'react-helmet';
import { TWILIO_WEBCHAT_URL } from '@gatsby-local-plugin/gatsby-local-env-variables';

const App = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href={`${TWILIO_WEBCHAT_URL}/main.23864068.css`}
          rel="stylesheet"
        ></link>
        <script defer src={`${TWILIO_WEBCHAT_URL}/main.js`}></script>
      </Helmet>
      {children}
      <div id="twilio-chat-component"></div>
    </>
  );
};

export default App;
