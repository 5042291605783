import { Types } from './actions';

import IAircraftFilterOptions from '@utils/interfaces/aircraft-filter-options.interface';

const INITIAL_STATE = {
  sales: {
    ...IAircraftFilterOptions,
  },
};

const FiltersReducer = (state = INITIAL_STATE, action) => {
  let newState = null;

  switch (action.type) {
    case Types.INIT_FILTERS:
      newState = {
        ...state,
        sales: {
          ...state.sales,
          ...(action.payload || null),
        },
      };
      return newState;

    case Types.UPDATE_FILTERS:
      newState = {
        ...state,
        sales: {
          ...state.sales,
          ...(action.payload || null),
        },
      };
      return newState;

    case Types.UPDATE_GROUP_BY:
      newState = {
        ...state,
        sales: {
          ...state.sales,
          groupBy: action.name,
        },
      };
      return newState;

    case Types.UPDATE_CURRENT_TAB:
      return {
        ...state,
        sales: {
          ...state.sales,
          currentTab: action.tab,
        },
      };

    case Types.SET_INITIAL_AUTONOMY:
      return {
        ...state,
        sales: {
          ...state.sales,
          airplanes: {
            ...state.sales.airplanes,
            originalAutonomy: action.payload.airplanes,
          },
          helicopters: {
            ...state.sales.helicopters,
            originalAutonomy: action.payload.helicopters,
          },
        },
      };

    default:
      return state;
  }
};

export default FiltersReducer;
