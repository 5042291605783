const jwt = require('jwt-simple');

/**
 * Returns token from json object
 *
 * @param {object} data Data object (json)
 * @param {string} secret Crypto key
 */
const encodeObjectToken = (data, secret) => {
  const encodedToken = jwt.encode(data, secret);
  return encodedToken;
};

/**
 * Returns token from json object
 *
 * @param {string} token Token generated by JWT
 * @param {string} secret Crypto key
 */
const decodeObjectToken = (token, secret) => {
  const decodedToken = jwt.decode(token, secret);
  return decodedToken;
};

export { encodeObjectToken, decodeObjectToken };
