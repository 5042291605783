import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    getAll: ['addresses'],
    add: ['address'],
    setStatus: ['status'],
    updateAddress: ['address'],
  },
  {
    prefix: 'ADDRESS_',
  }
);

const INITIAL_STATE = {
  addresses: [],
  isFetching: null,
};

const getAll = (state = INITIAL_STATE, action) => ({
  ...state,
  addresses: action.addresses,
});

const add = (state = INITIAL_STATE, action) => ({
  ...state,
  addresses: [action.address, ...state.addresses],
  isFetching: 'fetched',
});

const setStatus = (state = INITIAL_STATE, action) => ({
  ...state,
  isFetching: action.status,
});

const updateAddress = (state = INITIAL_STATE, action) => {
  const addresses = state.addresses.map(address => {
    if (address.id === action.address.id) {
      return action.address;
    } else {
      return address;
    }
  });

  return { ...state, addresses: addresses };
};

const HANDLERS = {
  [Types.GET_ALL]: getAll,
  [Types.ADD]: add,
  [Types.SET_STATUS]: setStatus,
  [Types.UPDATE_ADDRESS]: updateAddress,
};

export default createReducer(INITIAL_STATE, HANDLERS);
