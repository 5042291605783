const isBrowser = () => typeof window !== 'undefined';

const getSession = () =>
  isBrowser() && window.localStorage.getItem('flapperUser')
    ? JSON.parse(window.localStorage.getItem('flapperUser'))
    : {};

const useAuth = getSession;

export default useAuth;
