import React, { useReducer, createContext } from 'react';

export const LangStateContext = createContext();
export const LangDispatchContext = createContext();

const initialState = {
  lang: 'pt_BR',
};

const langReducer = (state, action) => {
  switch (action.type) {
    case 'setLang': {
      return {
        ...state,
        lang: action.lang,
      };
    }
    default:
      throw new Error('Bad action type');
  }
};

const LangContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(langReducer, initialState);
  return (
    <LangStateContext.Provider value={state}>
      <LangDispatchContext.Provider value={dispatch}>
        {children}
      </LangDispatchContext.Provider>
    </LangStateContext.Provider>
  );
};

export default LangContextProvider;
