import { useState, useEffect } from 'react';
import { handleUpdatePosition } from './auth';

const watchPositionCallback = (newPosition, oldPosition, setPosition) => {
  const { latitude: newLatitude, longitude: newLongitude } = newPosition.coords;

  if (
    oldPosition?.coords?.latitude !== newLatitude ||
    oldPosition?.coords?.longitude !== newLongitude
  ) {
    setPosition(newPosition);
    handleUpdatePosition(newPosition);
  }
};

export const PositionProvider = ({ children }) => {
  const [watchID, setWatchID] = useState(null);
  const [position, setPosition] = useState({});

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(newPosition =>
      watchPositionCallback(newPosition, position, setPosition)
    );
    setWatchID(id);
    return () => {
      navigator.geolocation.clearWatch(watchID);
    };
  }, [position]);

  return children;
};
